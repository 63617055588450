export class MinesightError extends Error {
  public readonly code?: string;

  constructor(message?: string, code?: string) {
    super(message);
    this.code = code;
  }
}

export const MissingSiteIdError: MinesightError = new MinesightError(
  'User does not have a site id. Please contact an administrator.',
  'MissingSiteId',
);

export const MultipleSiteIdError: MinesightError = new MinesightError(
  'User has multiple siteIds. Please contact an administrator.',
  'MultipleSiteId',
);

export const DifferentSiteIdError: MinesightError = new MinesightError(
  'The siteId which was assigned at login has changed in the user configuration. Please contact an administrator.',
  'DifferentSiteId',
);

export const NoUserPermissionsError: MinesightError = new MinesightError(
  'The user is misconfigured and missing permissions. Please contact an administrator.',
  'NoUserPermissions',
);
export const GenericLoginError: MinesightError = new MinesightError(
  'There was an issue logging in. Please contact an administrator.',
  'GenericLoginError',
);
export const MTlsLoginError: MinesightError = new MinesightError(
  'User is not registered with passwordless login, please login with regular credentials.',
  'MTlsLoginError',
);

export const MTlsWrongCertError: MinesightError = new MinesightError(
  'Passwordless login failed. Please try again using the MineSight certificate.',
  'MTlsWrongCertError',
);

export const InvalidSessionError: MinesightError = new MinesightError(
  'The session is invalid. Please contact an administrator.',
  'InvalidSessionError',
);

export const MTlsResponseNotMineCertErrorMessage = 'Cert Common Name must begin with';
export const MTlsResponseInvalidCertErrorMessage = 'Failed to fetch';
export const MTlsResponseInvalidCertErrorMessageIos = 'Load failed';

export const InvalidCertChosenMessages = [
  MTlsResponseInvalidCertErrorMessage,
  MTlsResponseInvalidCertErrorMessageIos,
];

export const RegistrationFailedNotMineCertMessage =
  'Registration failed. Please try again using the MineSight certificate.';

export const ShouldFailDueToIncorrectCertification = (
  errorMessage: string | undefined,
): boolean => {
  if (!errorMessage) return false;
  return (
    errorMessage.startsWith(MTlsResponseNotMineCertErrorMessage) ||
    InvalidCertChosenMessages.includes(errorMessage)
  );
};
