import { i18n } from '@nutrien/cxp-components';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import postal from 'postal';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useFlags } from '@/featureFlags/useFlags';
import { useNotification } from '@/utilities';
import { ApiStatus } from '@/utilities/apiStatus';
import {
  DEVICE_REGISTRATION_FLAG_KEY,
  MTLS_SELECTED_BORER_ID_KEY,
  USER_EMAIL_ID,
} from '@/utilities/constants';
import useEventTracking, { TrackingEventType } from '@/utilities/hooks/useEventTracking';
import { RegistrationFailedNotMineCertMessage } from '@/utilities/minesightErrors';

import { Borer } from '../../graphql/Equipment';
import { rootStore, useMst } from '../../mobx-models/Root';
import BorerSelector from '../BorerSelector';

const Home: React.FC = () => {
  const { successNotification, errorNotification } = useNotification();
  const { trackEvent } = useEventTracking();
  const { rehabBorersVisible } = useFlags();

  const { equipment } = useMst();
  const history = useHistory();

  const [borerSelectOpen, setBorerSelectOpen] = useState(equipment.selectedBorerId === undefined);

  const trackBorerSelection = () => {
    Sentry.setContext('siteMetadata', {
      borerName: equipment.selectedBorerName,
      siteName: rootStore.user.getSiteName(),
      siteId: rootStore.user.siteId,
    });

    postal.publish({
      channel: 'borer',
      topic: 'borer.selected',
    });
  };

  const setup = useCallback(async () => {
    try {
      // Only call if we don't have the list of borers from previous login step (ie page refresh)
      if (!equipment.getBorerList(true, true).length) {
        await equipment.fetchBorerList();
      }
    } catch (error) {
      console.log('🚀 ~ file: Home.tsx ~ line 36 ~ setup ~ error', error);
    }

    const mtlsSelectedBorerId = localStorage.getItem(MTLS_SELECTED_BORER_ID_KEY);
    if (mtlsSelectedBorerId) {
      equipment.selectBorer(mtlsSelectedBorerId, false).then(() => {
        localStorage.removeItem(MTLS_SELECTED_BORER_ID_KEY);
      });

      trackBorerSelection();
    }

    if (equipment.selectedBorerId !== undefined) {
      history.push('/initializing?initialLoad=true');
    }
  }, [equipment]);

  useEffect(() => {
    setup();
  }, [setup]);

  const assignBorer = (borer: Borer) => {
    const shouldRegister = localStorage.getItem(DEVICE_REGISTRATION_FLAG_KEY) === 'true';
    equipment.selectBorer(borer.id, shouldRegister).then(() => {
      if (equipment.CreateRegistrationStatus === ApiStatus.SUCCESS) {
        trackEvent(TrackingEventType.MTLS_REGISTRATION_SUCCESS, {
          data: JSON.stringify({
            userName: localStorage.getItem(USER_EMAIL_ID),
            borerId: borer.id,
          }),
        });
        successNotification(i18n.t('Registration successful'));
      } else if (equipment.CreateRegistrationStatus === ApiStatus.ERROR) {
        if (equipment.RegistrationWrongCertErrorMessage) {
          errorNotification(i18n.t(RegistrationFailedNotMineCertMessage));
        } else {
          errorNotification(i18n.t('Registration failed'));
        }
      }
      localStorage.removeItem(DEVICE_REGISTRATION_FLAG_KEY);
    });
    setBorerSelectOpen(false);

    trackBorerSelection();

    history.push('/initializing?initialLoad=true');
  };

  return (
    <>
      <BorerSelector
        open={borerSelectOpen}
        listOfBorers={equipment.getBorerList(true, rehabBorersVisible)}
        assignBorer={assignBorer}
      />
    </>
  );
};

export default observer(Home);
