import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  i18n,
  Icons,
  TextField,
} from '@nutrien/cxp-components';
import React, { FocusEvent, useState } from 'react';

import { useFlags } from '@/featureFlags/useFlags';
import {
  GenericLoginError,
  MinesightError,
  MissingSiteIdError,
  MTlsLoginError,
  MTlsWrongCertError,
  MultipleSiteIdError,
  NoUserPermissionsError,
} from '@/utilities/minesightErrors';

import { useOnlineStatus } from '../../../utilities';
import { NOT_AUTHORIZED_CODE, USER_NOT_FOUND_CODE } from '../../../utilities/constants';
import OfflineStatusDialog from '../../OfflineStatusDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100vh',
    },
    card: {
      padding: '70px 40px',
      borderRadius: '10px',
      backgroundColor: `${theme.palette.background.paper}`,
    },
    imageContainer: {
      marginBottom: '50px',
      textAlign: 'center',
    },
    icon: {
      '& svg': {
        marginTop: '2px',
      },
    },
  }),
);

interface Props {
  loginUser: (email: string, password: string) => Promise<void>;
  isLoggingIn: boolean;
  error?: MinesightError;
  clearError: () => void;
  useMTlsLogin: boolean;
  setUseMTlsLogin: (value: boolean) => void;
  registerIPad: boolean;
  setRegisterIPad: (value: boolean) => void;
}

const LoginArea = ({
  loginUser,
  isLoggingIn,
  error,
  clearError,
  useMTlsLogin,
  setUseMTlsLogin,
  registerIPad,
  setRegisterIPad,
}: Props) => {
  const classes = useStyles();

  const { msBorerDataSync, passwordlessLogin } = useFlags();

  const [email, setEmail] = useState('');
  const [password, setPasssword] = useState('');
  const online = useOnlineStatus();
  const handleEmailChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value.toLowerCase());
    if (error && error.code) {
      clearError();
    }
  };

  const handlePasswordChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPasssword(value);
    if (error && error.code) {
      clearError();
    }
  };

  const handleLogin = async () => {
    loginUser(email, password);
  };

  const getErrorText = (): string => {
    if (error?.code) {
      switch (error.code) {
        case USER_NOT_FOUND_CODE:
        case NOT_AUTHORIZED_CODE:
          return i18n.t('The email or password is incorrect. Try again.');
        case MissingSiteIdError.code:
          return MissingSiteIdError.message;
        case MultipleSiteIdError.code:
          return MultipleSiteIdError.message;
        case NoUserPermissionsError.code:
          return NoUserPermissionsError.message;
        case GenericLoginError.code:
          return GenericLoginError.message;
        case MTlsLoginError.code:
          return MTlsLoginError.message;
        case MTlsWrongCertError.code:
          return MTlsWrongCertError.message;
        default:
          return i18n.t('An error occurred logging in. Try again.');
      }
    }
    return i18n.t('An error occurred logging in. Try again.');
  };

  return online ? (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xs={8} md={6} className={classes.card}>
        <div className={classes.imageContainer}>
          <img src="../../minesight_borer.svg" alt="MineSight Borer" />
        </div>
        {!useMTlsLogin && (
          <>
            <TextField
              variant="outlined"
              color="primary"
              label={i18n.t('Email')}
              onChange={handleEmailChange}
              type="email"
              inputProps={{
                id: 'email-input',
                'data-testid': 'email-input',
              }}
            />
            <TextField
              variant="outlined"
              color="primary"
              label={i18n.t('Password')}
              onChange={handlePasswordChange}
              type="password"
              inputProps={{
                id: 'password-input',
                'data-testid': 'password-input',
              }}
            />
            {passwordlessLogin && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setRegisterIPad(!registerIPad);
                    }}
                    name="register-checkbox"
                    checked={registerIPad}
                    css={undefined}
                    data-testid={'register-checkbox'}
                  />
                }
                label={i18n.t('Register this iPad for passwordless login')}
              />
            )}
          </>
        )}
        {error?.code && (
          <Grid container alignItems="center">
            <Grid item xs={1} className={classes.icon}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" color="error" align="left">
                {getErrorText()}
              </Typography>
            </Grid>
            <br />
          </Grid>
        )}
        {msBorerDataSync?.allowSync === false && (
          <Grid container alignItems="flex-start">
            <Grid item xs={1} className={classes.icon}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" color="error" align="left">
                {msBorerDataSync?.message || ''}
              </Typography>
            </Grid>
            <br />
          </Grid>
        )}
        {msBorerDataSync?.allowSync !== false && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handleLogin}
              disabled={isLoggingIn || ((email === '' || password === '') && !useMTlsLogin)}
              isLoading={isLoggingIn}
              id="login-button"
              data-testid="login-button"
            >
              {useMTlsLogin ? 'Passwordless Login' : 'Login'}
            </Button>
            {passwordlessLogin && (
              <Grid item>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    clearError();
                    setUseMTlsLogin(!useMTlsLogin);
                  }}
                  id="login-type-button"
                  data-testid="login-type-button"
                >
                  {useMTlsLogin ? 'Login with password' : 'Passwordless Login'}
                </Button>
              </Grid>
            )}
          </>
        )}{' '}
      </Grid>
    </Grid>
  ) : (
    <OfflineStatusDialog open={!online} />
  );
};

export default LoginArea;
