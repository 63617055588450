import axios from 'axios';

import { callAPI } from '@/api/apiUtilities';
import { apiConfig } from '@/config/api.config';
import { MTlsRegistrationResponse, SecretLoginKey } from '@/models/models';
import {
  MTlsLoginError,
  MTlsWrongCertError,
  ShouldFailDueToIncorrectCertification,
} from '@/utilities/minesightErrors';

const postApi = async (url: string): Promise<any> => {
  let responseData: any;
  await axios
    .post(url, {})
    .then(async response => {
      responseData = response.data;
    })
    .catch(error => {
      if (error.response?.data) {
        responseData = error.response?.data;
      } else {
        throw MTlsWrongCertError;
      }
    });

  if (!responseData.success) {
    if (ShouldFailDueToIncorrectCertification(responseData.errorMessage)) {
      throw MTlsWrongCertError;
    }
    throw MTlsLoginError;
  }

  return responseData;
};

export const pingTest = async (): Promise<any> => {
  let passedMTls = false;
  await axios
    .get(`${apiConfig.mTlsUrl}/test`, {})
    .then(() => {
      // Handle success
      passedMTls = true;
    })
    .catch(() => {
      // do nothing
      passedMTls = false;
    });

  return passedMTls;
};

export const checkRegistration = async (): Promise<MTlsRegistrationResponse> => {
  return await postApi(`${apiConfig.mTlsUrl}/checkRegistration`);
};

export const GetSecret = async (): Promise<SecretLoginKey> => {
  return await postApi(`${apiConfig.mTlsUrl}/getSecretLoginKey`);
};

export const createRegistration = async (borerId: string) => {
  let response: any;
  try {
    response = await callAPI(`${apiConfig.mTlsUrl}/createRegistration`, {
      method: 'post',
      body: JSON.stringify({
        borerId,
      }),
    });
  } catch (error) {
    if (ShouldFailDueToIncorrectCertification(error?.message)) {
      throw MTlsWrongCertError;
    }
    throw error;
  }
  return response;
};

export const isCurrentUserRegistered = (borerId: string) =>
  callAPI(`${apiConfig.hazardsRootUrl}/checkRegistration`, {
    method: 'POST',
    body: JSON.stringify({
      borerId,
    }),
  });
